var toolTips = function () { 	
	const init = () =>{
		const tooltipEls = document.getElementsByClassName("tooltipTrigger");
		for(let i = 0; i < tooltipEls.length; i++){
			tooltipEls[i].removeEventListener("click", toggleTip);
			tooltipEls[i].addEventListener("click", toggleTip);
			
			tooltipEls[i].removeEventListener("blur", closeTip);
			tooltipEls[i].addEventListener("blur", closeTip);
		}
	};
	
	const initSingleElem = (elem) => {
		elem.removeEventListener("click", toggleTip);
		elem.addEventListener("click", toggleTip);
		
		elem.removeEventListener("blur", closeTip);
		elem.addEventListener("blur", closeTip);
	};
	 
	const toggleTip = (e) =>{
		e.preventDefault();
		const el = e.currentTarget;
		console.log(el);
		if(el.nextElementSibling.className == "visible") closeTip();
		else showTip(el);
	};
	
	const showTip = (tipToShow) =>{
		tipToShow.classList.add("activeTip");
		tipToShow.setAttribute("aria-expanded", "true");
		tipToShow.nextElementSibling.classList.add("visible");
		tipToShow.nextElementSibling.setAttribute("aria-hidden", "false");
		document.addEventListener('click', hideOnClickOutside);
		document.addEventListener('touchstart', hideOnClickOutside);
		document.addEventListener("keyup", escapeTip);
	};
	
	const closeTip = () =>{		
		document.removeEventListener('click', hideOnClickOutside);
		document.removeEventListener('touchstart', hideOnClickOutside);
		document.removeEventListener("keyup", escapeTip);
		const activeTip = document.getElementsByClassName("activeTip");
		if(activeTip.length > 0){
			activeTip[0].setAttribute("aria-expanded", "false");
			activeTip[0].nextElementSibling.classList.remove("visible");
			activeTip[0].nextElementSibling.setAttribute("aria-hidden", "true"); 
			activeTip[0].classList.remove("activeTip");
		}
	};
	
	const hideOnClickOutside = (e) =>{
		const activeTip = document.getElementsByClassName("activeTip");
		if (activeTip.length > 0 && !activeTip[0].contains(e.target)) closeTip();
	};
	
	const escapeTip = (e) =>{
		if((e.which || e.keyCode) === 27) closeTip();
	};
	
	return {
		init: init,
		initSingleElem: initSingleElem
	};
};